import React from "react"
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ShareButtons from "../components/share-buttons"
import CTA from "../components/cta"
import ExplorerTag from "../components/explorer-tag"
import { explorersMap } from "../helpers/color"
import "./blog-post.scss"

const BlogPostTemplate = ({ data }) => {
  const post = data && data.contentfulBlogPost
  const url =
    (typeof window !== "undefined" && window.location.href) ||
    `https://shapepowered.com/blog/${post.slug}`
  console.log(post)
  return (
    <Layout>
      <div style={{ background: "#fff" }} className="blogPostWrapper">
        <SEO
          title={post.title}
          meta={[
            {
              property: "og:title",
              content: post.title,
            },

            {
              property: "og:description",
              content: post.subtitle,
            },
            {
              property: "og:url",
              content: url,
            },
          ]}
        />
        <div className="hero-imageBlog">
          {/* <Img alt={post.title} fluid={post.heroImage.fluid} /> */}
          <GatsbyImage
            image={post.heroImage.gatsbyImageData}
            alt={post.title}
            quality={100}
          />
        </div>

        <Container className="content-wrapperBlog">
          {post.tags && post.tags.length > 0 && (
            <div className={`blog-tag ${explorersMap(post?.tags[0])}`}>
              {post.tags[0]}
            </div>
          )}
          <h1 className="title">
            {post.title}
            <br />
            <small>{post.subtitle}</small>
          </h1>
          <div className="info-bar">
            {/* <div className="date-type">{post.publishDate}</div> */}
            <div className="share-buttons">
              <ShareButtons url={url} title={post.title} size={25} />
            </div>
          </div>
          <hr />
          <div
            className="contentBlog"
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />
          <div className="explorer-tag">
            <ExplorerTag tag={post.tags[0]} />
          </div>
        </Container>
      </div>
    </Layout>
  )
}
export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      tags
      subtitle
      publishDate(formatString: "MMMM D, YYYY")
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, breakpoints: [5750])
      }
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
