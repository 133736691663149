import React from 'react';
import PropTypes from 'prop-types';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,

  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  RedditIcon,
  EmailIcon,
} from 'react-share';

import './share-buttons.scss';

const SocialShareButtons = ({title, url, description, className, size}) => {
  console.log(url)
  const buttonProps = { title, url, description };
  const iconsProps = {
    round: true,
    size,

    // iconBgStyle: {
    //   fill: 'white'
    // }
  };

  return (
    <div className="share-buttonsFlex" >
      <FacebookShareButton {...{...buttonProps, title: undefined}} quote={title}>
        <FacebookIcon {...iconsProps} />
      </FacebookShareButton>
      <TwitterShareButton {...buttonProps}>
        <TwitterIcon {...iconsProps} />
      </TwitterShareButton>
      <WhatsappShareButton {...buttonProps}>
        <WhatsappIcon {...iconsProps} />
      </WhatsappShareButton>
      <LinkedinShareButton {...buttonProps}>
        <LinkedinIcon {...iconsProps} />
      </LinkedinShareButton>
      <RedditShareButton {...buttonProps}>
        <RedditIcon {...iconsProps} />
      </RedditShareButton>
      <EmailShareButton {...buttonProps} subject={title}>
        <EmailIcon {...iconsProps} />
      </EmailShareButton>
    </div>
  );
}

SocialShareButtons.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number
};

SocialShareButtons.defaultProps = {
  title: 'SHAPE - A better you',
  size: 30
};

export default SocialShareButtons;
