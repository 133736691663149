
  const explorersModule = {
    aboutyou: [
      "personal work ethic",
      "physical health",
      "psychological health",
      "factors outside work",
    ],
    thefacilities: ["Physical Work Environment", "Soft Work Environment"],
    thecompany: ["Business Fundamentals", "Corporate Culture"],
    yournetwork: [
      "Management Style",
      "Job Satisfaction",
      "Employee Engagement",
      "Employee Commitment",
    ],
  }

export const explorersMap = tag => {
    let module = "random"
    Object.entries(explorersModule).map(([key, value]) => {
      if (value.find(y => y.toLowerCase() === tag.toLowerCase())) {
        module = key
      }
    })
    return module
  }
  