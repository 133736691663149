import React from "react"
import CC from "../images/explorers/corporate-culture.png"
import EC from "../images/explorers/employee-commitment.png"
import EE from "../images/explorers/employee-engagement.png"
import FO from "../images/explorers/factors-outside-work.png"
import JS from "../images/explorers/job-satisfaction.png"
import MS from "../images/explorers/manager-style.png"
import PE from "../images/explorers/personal-work-ethic.png"
import PH from "../images/explorers/physical-health.png"
import PWE from "../images/explorers/physical-work-environment.png"
import PSY from "../images/explorers/psychological-health.png"
import BE from "../images/explorers/business-essentials.png"
import SWE from "../images/explorers/soft-work-environment.png"
import CD from "../images/explorers/coworker.png"
const images = {
  "corporate-culture": CC,
  "employee-commitment": EC,
  "employee-engagement": EE,
  "factors-outside-work": FO,
  "job-satisfaction": JS,
  "management-style": MS,
  "personal-work-ethic": PE,
  "physical-health": PH,
  "physical-work-environment": PWE,
  "psychological-health": PSY,
  "business-essentials": BE,
  "soft-work-environment": SWE,
  "coworker-dynamic": CD,
}

const formatTag = (tag) => tag.split(" ").join("-").toLowerCase()

export default function ExplorerTag({ tag }) {
  if (!tag) return null
  const src = images[formatTag(tag)]
  return <img src={src} alt={tag} />
}
